import React from 'react'
import { NavLink } from 'react-router-dom'

import homeIco from "@/assets/images/Home.svg"
import questIco from "@/assets/images/Quest.svg"
import stakingIco from "@/assets/images/Staking.svg"
import frensIco from "@/assets/images/Frens.svg"
import swapIco from "@/assets/images/Swap.svg"


interface IFooter {
    className?: string
}
export const Footer:React.FC<IFooter> = ({className}) => {
  return (
    <div className='flex gap-[30px] w-full bg-black fixed bottom-0 left-0 justify-center pb-2 pt-5 z-20'>

        <NavLink to="/" className={({ isActive }) => isActive ? 'activeMnu' : 'flex flex-col justify-center items-center text-[#a4a4a6] text-sx font-medium'}>
            <div className='rounded-[50%] bg-[#A4A4A6] w-6 h-6 flex items-center justify-center '><img src={homeIco} alt="" /></div>
            <span className='block text-xs'>Home</span>
        </NavLink>
        <NavLink to="/quest" className={({ isActive }) => isActive ? 'activeMnu' : 'flex flex-col justify-center items-center text-[#a4a4a6] text-sx font-medium'}>
        <div className='rounded-[50%] bg-[#A4A4A6] w-6 h-6 flex items-center justify-center '><img src={questIco} alt="" /></div>
            <span className='block text-xs'>Quest</span>
        </NavLink>
        <NavLink to="/staking" className={({ isActive }) => isActive ? 'activeMnu' : 'flex flex-col justify-center items-center text-[#a4a4a6] text-sx font-medium'}>
            <div className='rounded-[50%] bg-[#A4A4A6] w-6 h-6 flex items-center justify-center '><img src={stakingIco} alt="" /></div>
            <span className='block text-xs'>Staking</span>
        </NavLink>
        <NavLink to="/frens" className={({ isActive }) => isActive ? 'activeMnu' : 'flex flex-col justify-center items-center text-[#a4a4a6] text-sx font-medium'}>
            <div className='rounded-[50%] bg-[#A4A4A6] w-6 h-6 flex items-center justify-center '><img src={frensIco} alt="" /></div>
            <span className='block text-xs'>Frens</span>
        </NavLink>
        <NavLink to="/swap" className={({ isActive }) => isActive ? 'activeMnu' : 'flex flex-col justify-center items-center text-[#a4a4a6] text-sx font-medium'}>
            <div className='rounded-[50%] bg-[#A4A4A6] w-6 h-6 flex items-center justify-center '><img src={swapIco} alt="" /></div>
            <span className='block text-xs'>Swap</span>
        </NavLink>
    </div>
  )
}