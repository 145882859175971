import React, {lazy, Suspense, useState} from 'react'
import {Routes, Route} from 'react-router-dom'
import Layout from '@/components/Layout';

const HomePage = lazy(()=>import(/*webpackChunkName: "Home" */'@/pages/home'));
const QuestPage = lazy(()=>import(/*webpackChunkName: "Quest" */'@/pages/quest'));
const StakingPage = lazy(()=>import(/*webpackChunkName: "Staking" */'@/pages/staking'));
const FrensPage = lazy(()=>import(/*webpackChunkName: "Frens" */'@/pages/frens'));
const SwapPage = lazy(()=>import(/*webpackChunkName: "Swap" */'@/pages/swap'));

function App() {

const [preloader, setPreloader] = useState<boolean>(true);
  if(preloader){
    setTimeout(()=>{      
      setPreloader(false)
    }, 2700)
}

  return (
    <div className="bg-black w-full max-w-[686px] h-[calc(100vh_-_44px)] m-auto relative overflow-y-auto pb-[38px]" >
      <Routes>
          <Route path='/' element={<Layout/>}>
              <Route index element={<Suspense><HomePage/></Suspense>} />
              <Route path="/quest" element={<Suspense><QuestPage/></Suspense>} />
              <Route path="/staking" element={<Suspense><StakingPage/></Suspense>} />
              <Route path="/staking/:id" element={<Suspense><StakingPage/></Suspense>} />
              <Route path="/frens" element={<Suspense><FrensPage/></Suspense>} />
              <Route path="/swap" element={<Suspense><SwapPage/></Suspense>} />
          </Route>
      </Routes>
      {/* <div id="preloader" className={preloader ? '' : 'preloader_hidden'}> <Lottie animationData={animationEvent} loop={true} /></div> */}
    </div>
  );
}

export default App;
