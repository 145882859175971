import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface CoinState {
  ax9: number,
  myBalance: number,
  activeCoin: string,
  coins:{
    "usdt": {
      status: boolean,
      amount: number,
    },
    "ton": {
      status: boolean,
      amount: number,
    },
    "not": {
      status: boolean,
      amount: number,
    },
    "dogs": {
      status: boolean,
      amount: number,
    }
  }
  
}

const initialState: CoinState = {
  ax9: 0,
  myBalance: 0,
  activeCoin: 'usdt',
  coins: {
    "usdt": {
      status: true,
      amount: 5,
    },
    "ton": {
      status: false,
      amount: 6,
    },
    "not": {
      status: false,
      amount: 7,
    },
    "dogs": {
      status: false,
      amount: 8,
    }
  }
  
}

export const coinSlice = createSlice({
  name: 'coins',
  initialState,
  reducers: {
    // increment: (state) => {
    //   state.value += 1
    // },
    // decrement: (state) => {
    //   state.value -= 1
    // },
    // incrementByAmount: (state, action: PayloadAction<number>) => {
    //   state.value += action.payload
    // },
    setActiveCoin: (state, action)=>{
      state.activeCoin = action.payload
    },
    setmyBalance: (state, action)=>{
      state.myBalance = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setActiveCoin } = coinSlice.actions

export default coinSlice.reducer