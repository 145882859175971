import React from 'react';
import {Outlet} from 'react-router-dom';
import { Footer } from './Footer';

// import {Header, Footer } from './'

 const Layout: React.FC = () => {
  return (
    <>
      <Outlet />
      <Footer />
    </>
  )
}

export default Layout;